export interface Asset {
  name: string | undefined;
  base64: string | undefined;
  path: string | undefined;
  mimeType?: string;

  title?: string;
  alt?: string;
}

export function emptyAsset(): Asset {
  return {
    name: undefined,
    base64: undefined,
    path: undefined,
  };
}

export function isAssetEmpty(asset: Asset): boolean {
  return !!(
    (asset.path && asset.path != '') ||
    (asset.base64 && asset.base64 != '')
  );
}

export function getAssetFromURL(
  url: string,
  title?: string,
  alt?: string,
): Asset {
  return {
    name: getFileNameFromUrl(url),
    base64: undefined,
    path: url,
    mimeType: getMimeTypeFromUrl(url),
    title: title,
    alt: alt,
  };
}

export function createDataUrl(asset: Asset): string {
  if (asset.path && asset.path !== '') {
    return asset.path;
  }
  return `data:${asset.mimeType};base64,${asset.base64}`;
}

function getFileNameFromUrl(urlString: string): string | undefined {
  const parsedUrl = new URL(urlString);
  const pathnameParts = parsedUrl.pathname.split('/');
  if (pathnameParts.length > 0) {
    return pathnameParts[pathnameParts.length - 1];
  }
  return undefined;
}

function getMimeTypeFromUrl(urlString: string): string {
  const parsedUrl = new URL(urlString);
  const ext = getFileExtension(parsedUrl.pathname || '');
  return getMimeTypeFromExtension(ext);
}

function getFileExtension(path: string): string {
  const lastDotIndex = path.lastIndexOf('.');
  return lastDotIndex !== -1 ? path.slice(lastDotIndex) : '';
}

function getMimeTypeFromExtension(extension: string): string {
  switch (extension.toLowerCase()) {
    case '.png':
      return 'image/png';
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
    case '.gif':
      return 'image/gif';
    case '.bmp':
      return 'image/bmp';
    case '.webp':
      return 'image/webp';
    case '.webm':
      return 'video/webm';
    case '.svg':
      return 'image/svg+xml';
    case '.pdf':
      return 'application/pdf';
    case '.mp4':
      return 'video/mp4';
    case '.mp3':
      return 'audio/mp3';
    case '.txt':
      return 'text/plain';
    case '.html':
      return 'text/html';
    case '.css':
      return 'text/css';
    case '.js':
      return 'application/javascript';
    case '.json':
      return 'application/json';
    case '.xml':
      return 'application/xml';
    case '.zip':
      return 'application/zip';
    case '.tar':
      return 'application/x-tar';
    case '.gz':
      return 'application/gzip';
    // Add more cases as needed
    default:
      return 'application/octet-stream';
  }
}
